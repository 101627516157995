import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import BoardMain from '@board/components/board/BoardMain.vue'
import SubContentTemplate from '@appbase/templates/SubContentTemplate.vue'
import { LoadingBar } from '@appbase/components/loading'

import api from '@board/api'
import * as usertypes from '@account/store/types';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import * as types from '@board/store/types'

@Component({
    components: {
        LoadingBar
    },
    computed: {
        ...mapGetters({
            user : usertypes.USER
        })
    },
    methods : {
        ...mapMutations({
            commitCoperationBoard : types.COPERATION_BOARD_INFO
        })
    }
})
export default class BoardPropxyView extends Vue {
    user! : account.TUser
    commitCoperationBoard! : (data : Array<board.CoperationBoard>) => void

    beforeCreate() {
        //api 콜해서 자기 법인 게시판으로 이동한다. 
        //MENUID & SYSID 를 찾아야됨 
        //조건은 게시판 설명란에 법인코드 + 법인명
        api.board.FindCoperationBoard().then(async result => {
            if(result.length > 0){
                this.commitCoperationBoard(result)

                let myCoperation = result.find(co=>{
                    return co.coperationCode == this.user.personCode.split("_")[0]
                })
                
    
                if(myCoperation){
                    this.$router.push({
                        name: 'boardproxy-listview',
                        query: { "MENUID": myCoperation.menuId.toString(), "SYSID": myCoperation.boardId.toString() }
                    })
                }
            }
            else{
                await (window as any).alertAsync("법인 게시판을 찾지 못하였습니다.")
                this.$router.go(-1)
            }
            
        })
       
        // this.$router.push({
        //     name: 'boardproxy-listview',
        //     query: { "MENUID": "558", "SYSID": "1138" }
        // })
    }
}
